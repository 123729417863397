import { Observer } from 'mobx-react-lite';
import React from 'react';
import DefaultPageHeader from '../../components/DefaultPageHeader/DefaultPageHeader';
import Line from '../../components/Line/Line';
import PageEndCTASection from '../../components/PageEndCTASection/PageEndCTASection';
import PageSection from '../../components/PageSection/PageSection';
import PageTemplate from '../../components/PageTemplate/PageTemplate';
import ProjectEntryList from '../../components/ProjectEntryList/ProjectEntryList';
import Spacer from '../../components/Spacer/Spacer';
import TricolorMorphDef from '../../components/TricolorMorphDef/TricolorMorphDef';
import { ClientMetaInfoFIA, useFIAInfo } from '../../content/clients/fia.client';
import { useAAAIDPAnimationInfo } from '../../content/projects/aaa-idp-animation.project';
import { useCarnetDePassagesInfo } from '../../content/projects/carnet-de-passages.project';
import { useFIARallyMarshalsTrainingInfo } from '../../content/projects/fia-rally-marshals-training.project';
import { useIDPInfo } from '../../content/projects/idp.project';
import { PageComponent } from '../../types/gatsby.types';
// import './ClientPageFIA.scss';

const ClientPageFIA: PageComponent = props => {
  const metaInfo = useFIAInfo();
  const projects = [
    useFIARallyMarshalsTrainingInfo(),
    useIDPInfo(),
    useAAAIDPAnimationInfo(),
    useCarnetDePassagesInfo(),
  ]
  return <Observer children={() => (
    <PageTemplate
      {...props}
      className="ClientPageFIA"
      title={ClientMetaInfoFIA.displayName}
      description={ClientMetaInfoFIA.description}
      backgroundImage={metaInfo.featuredImage}
    >

      <TricolorMorphDef />

      <DefaultPageHeader
        id="ClientPageFIAHeaderSection"
        title={metaInfo.displayName}
        titleFontSize="lg"
        subtitle={metaInfo.subtitle}
      />

      <PageSection id="ClientPageFIAContentSection">
        <Line />
        <Spacer size="pageMargin" />
        <ProjectEntryList projects={projects} />
      </PageSection>

      <Spacer size="pageMargin" />

      <PageEndCTASection />

    </PageTemplate>
  )} />
}

export default ClientPageFIA;